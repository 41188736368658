import React from 'react';
import Feature from '../../components/feature/Feature';
import './course.css';

const featuresData = [
  {
    title: 'Astanga Yoga',
    text2: 'Urform des Yogas nach Pathanjali und Thirumular. Aus Astanga Yoga entwickelten sich alle weiteren Formen des Yogas. Schwerpunkt liegt in der Kombination der acht Wege des Lebens.',
  },
  {
    title: 'Stundenplan',
    text2: 'Montag - Freitag: 7 Uhr - 13 Uhr / 17 Uhr - 21 Uhr',
    text3: 'Samstag/Sonntag: 6.30 Uhr - 12 Uhr',
  },
];

const Course = () => {
  return (
    <div className="gpt3__features section__padding" id="course">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">Kursangebot</h1>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text2={item.text2} text3={item.text3} key={item.title + item.text4 + index} />
      ))}
    </div>
    </div>
  )
}

export default Course