import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatYOGA.css';


const WhatYOGA = () => {
  return (
    <div className="gpt3__whatgpt3 section__margin" id="wy">
          <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">WAS IST YOGA?</h1>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="" text="Yoga ist eine alte Lebensweisheit, welche ihre Wurzeln in Indien hat. Mit Hilfe von Yoga können wir unseren Körper und Geist disziplinieren und die Wahrnehmung auf unser Leben sensibilisieren. Es ist für alle und jeden in jeder Lebenssituation. Durch die flexible Philosophie entwickelt es sich stetig weiter passt sich auch an die soziale Veränderung an. Viele Yogis passen durch die ständigen Veränderungen der Bedürfnissen ihre Praktiken den gegebenen Umständen, wie auch den verschiedenen Bedürfnissen der Menschen an. Durch Yoga können unbekannte Fähigkeiten frei gelegt werden, wobei wir unser volles Potential auszuschöpfen lernen." />
      <Feature title="" text="Das Wort Yoga leitet sich vom Sanskript „yuj“ ab und bedeutet so viel wie Yoga zu sein. Die Weiterentwicklung von Yoga hat verschiedene Arten von Yoga hervorgerufen. Das Hauptziel ist jedoch identisch: Die eigene Persönlichkeit weiter zu entwickeln und fördern. Ein glückliches, ausgeglichenes und zufriedenes Leben zu führen und sich dabei gesund und stark im eigenen Körper zu fühlen. Was idealerweise zu –Samadhi- einem kosmischen Bewusstsein führt. Die hinduistische Tradition besagt, wer Yoga praktiziert kommt in Kontakt mit dem universellen Geist – Brahman.Damit man durch Yoga die eigene Persönlichkeit schaffen und deren Tiefe kennen lernen kann, soll es geübt und erfahren werden. Folgende Punkte können sich positiv bei regelmässigem praktizieren zeigen:" />
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Energiereich durch das alltägliche Leben" />
      <Feature title="Ausgeglichenheit in Erfolg und Misserfolg" />
      <Feature title="Freiheit und Unabhängigkeit" />
      <Feature title="Zugang zum universellen Bewusstsein" />
      <Feature title="Selbstkontrolle" />
      <Feature title="Schmerzlinderung" />
      <Feature title="Klarheit im Geist und den Handlungen" />
    </div>
    <div className="gpt3__whatgpt3-container">
    <Feature text="Yoga ist eine Geistes- und Körperwissenschaft, die mit keiner Religion in Verbindung steht. Während andere physische Wissenschaften sich mit dem äusseren Wohlbefinden beschäftigen, richtet sich die Aufmerksamkeit von Yoga nach Innen."/>
    </div>
    </div>
  )
}

export default WhatYOGA;