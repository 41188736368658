import React, {useState} from 'react'
import './contact.css';
import map from '../../assets/map-pin.svg';
import mail from '../../assets/mail.svg';
import phone from '../../assets/phone.svg';


export default function App() {
  const [query, setQuery] = useState({
    name: "",
    email: ""
  });

  // const Contact = () => (
  //   <div className="gpt3__cta">
  //     <div className="gpt3__cta-content">
  //       <p>Kontakt</p>
  //       <h3>Register Today & start exploring the endless possibilities.</h3>
  //     </div>
  //     <div className="gpt3__cta-btn">
  //       <button type="button">Get Started</button>
  //     </div>
  //   </div>
  // );
  
  // Update inputs value
  const handleParam = () => (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  // Form Submit function
  const formSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(query).forEach(([key, value]) => {
      formData.append(key, value);
    });
    fetch("https://getform.io/f/65ff8750-3052-4e9d-8954-c8cbf7290455", {
      method: "POST",
      body: formData
    }).then(() => setQuery({ name: "", email: "", message: "" }));
  };
   return (
    <div className="yoga__contact section__padding" id="contact">
      <div className="yoga__contact-heading">
        <h1 className="gradient__text1">Kontakt</h1>
      </div>
      <div className="yoga__contact-info">
      <div className=''>
        
      <h3 className="gradient__text1"><sub><img src={map} alt="map" /></sub> Adresse</h3>
      <p>Gerliswilstrasse 17 <br/> 6020 Emmenbrücke</p>
      <h3 className="gradient__text1"><sub><img src={phone} alt="phone" /></sub> Telefon</h3>
      <p> 076 262 02 38</p>
      <h3 className="gradient__text1"><sub><img src={mail} alt="mail" /></sub> E-Mail</h3>
      <p>info@aathi-yoga.ch</p>
      </div>
      </div>
      <div className="yoga__contact-container">
        <form onSubmit={formSubmit} >
          <div className='yoga__contact-name'>
            <input
              type="text"
              name="name"
              required
              placeholder="Name"
              className="yoga__contact-name-input"
              value={query.name}
              onChange={handleParam()}
            />
          </div>
          <div className='yoga__contact-mail'>
            <input
              name="email"
              required
              placeholder="Email-Adresse"
              className="yoga__contact-mail-input"
              value={query.email}
              onChange={handleParam()}
            />
          </div>
               <div className='yoga__contact-message'>
               <textarea
                  name="message"
                  required
                  placeholder="Nachricht"
                  rows="10"
                  className="yoga__contact-message-input"
                  value={query.message}
                  onChange={handleParam()}
               />
               </div>
               <div className='yoga__contact-btn1'>
               <button
                  type="submit"
                  className="yoga__contact-btn">Absenden
               </button>
               </div>
            </form>
         </div>
         </div>

   )
}
