import React from 'react';
import threeyoga from '../../assets/yoga_PNG55.png';
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Willkommen bei Aathi Yoga in Luzern!</h1>
      <p>AATHI YOGA ist ein im Jahr 2006 gegründeter Verein. Das Ziel des Vereins ist die Förderung des Verständnisses für die ursprüngliche Einstellung und Formen des Yogas. In Seminaren und im wöchentlichen Training werden die Ansichten und Praktiken erklärt, eingeübt und gelebt. AATHI YOGA versteht sich als Gegenbewegung zum aktuellen Yoga-Hype. Nicht der massgeschneiderte Kurs für die schnelle körperliche Ertüchtigung wird angeboten, sondern das längerfristige Engagement für die Verbindungen zwischen Körper, Geist und Welt steht im Mittelpunkt von AATHI YOGA.</p>
    </div>

    <div className="gpt3__header-image">
      <img src={threeyoga} alt="Drei Yogis" />
    </div>
  </div>
);

export default Header;
