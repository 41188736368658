import React from 'react';
import logo from '../../logo.png';
import logo1 from '../../assets/logo1.png';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Entdecke dich selbst, Entdecke Yoga!</h1>
    </div>

    <div className="gpt3__footer-btn">
      <p><a href="#contact">Jetzt loslegen</a></p>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={logo} alt="Logo" />
        {/* <p>Gerliswilstrasse 17 <br/> 6020 Emmenbrücke</p> */}
      </div>
       <div className="gpt3__footer-links_div">
        <h4>Weiterführende Links</h4>
        <p><a href="https://naku-emmen.ch/yoga.html" target="_blank" rel="noopener noreferrer">Aathi Yoga auf der NAKU Webseite</a></p>
        <p><a href="https://naku-emmen.ch/" target="_blank" rel="noopener noreferrer">Nachhilfe und Kulturschule</a></p>
        <p><a href="https://audeis.ch/" target="_blank" rel="noopener noreferrer">AUDEIS</a></p>
      </div> 
      {/*<div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>Contact</p>
      </div> */}
      <div className="gpt3__footer-links_div">
        <h4>In Verbindung treten</h4>
        <p><a href="https://goo.gl/maps/wKFj26rYq26cbLyn8" target="_blank" rel="noopener noreferrer">Gerliswilstrasse 17, 6020 Emmenbrücke</a></p>
        <p>076 262 02 38</p>
        <p><a href="mailto:info@aathi-yoga.ch" target="_blank" rel="noopener noreferrer">info@aathi-yoga.ch</a></p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>Erstellt von<br/> <a href="https://www.barthansiva.ch" target="_blank" rel="noopener noreferrer"><img src={logo1} alt="Logo1" /></a><br/> Ⓒ 2022 Alle Rechte vorbehalten.</p>
    </div>
  </div>
);

export default Footer;

