import React from 'react';

import { Footer, Contact, Home, WhatYOGA, Course, Header } from './containers';
import { CTA, Brand, Navbar } from './components';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar />
        <Header />
        <WhatYOGA />
        <Course />
        <Contact />
        <Footer />
      </div>
    </div>
  )
}

export default App