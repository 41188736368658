import React from 'react';
import './feature.css';

const Feature = ({ title, text, text1, text2, text3 }) => (
  <div className="gpt3__features-container__feature">
    <div className="gpt3__features-container__feature-title">
      <div />
      <h1>{title}</h1>
    </div>
    <div className="gpt3__features-container_feature-text">
      <p>{text}<br/>{text1}<br/></p>
      <p1>{text2}<br/>{text3}</p1>
    </div>
  </div>
);

export default Feature;
